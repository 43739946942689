import { Cart } from '../Models/Cart.js'
import { bake_cookie, read_cookie } from 'sfcookies'

let _instance = null;
const prefix = "idea_";

export class Storage {

	static isAvailableStorage() {
		return (typeof (Storage) !== "undefined");
	}

	static instance() {
		if (!_instance) {
			if (Storage.isAvailableStorage)
				_instance = new LStorage();
			else
				_instance = null;//zatim p5edpokladam podporu localstorage, pokud bude potreba i podpora starsich, doplnim
		}
		return _instance;
	}
}

class LStorage {

	constructor() {
		this.isMasterLoading = false;
	}

	getItem(key) {
		return localStorage.getItem(prefix + key);
	}
	setItem(key, value) {
		localStorage.setItem(prefix + key, value);
	}
	removeItem(key) {
		localStorage.removeItem(prefix + key);
	}

	getSessionItem(key) {
		return sessionStorage.getItem(prefix + key);
	}
	setSessionItem(key, value) {
		sessionStorage.setItem(prefix + key, value);
	}
	removeSessionItem(key) {
		sessionStorage.removeItem(prefix + key);
	}

	isIE() {
	const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
	const msie = ua.indexOf('MSIE '); // IE 10 or older
	const trident = ua.indexOf('Trident/'); //IE 11

	return (msie > 0 || trident > 0);
}

	isSegmentUser() {
		return true;
		var tg = localStorage.getItem('abt5tg');
		var td = localStorage.getItem('abt5td');

		if (!tg || td < (new Date()).getTime()) {
			if (location.search.indexOf('gclid=') > -1
				||
				!document.referrer
				||
				navigator.userAgent.toLowerCase().indexOf('firefox') > -1
				||
				window.safari
				||
				this.isIE()
				||
				screen.width <= 900
			) {
				tg = 'true';
			} else {
				tg = 'false';
			}
			localStorage.setItem('abt5tg', tg);
			var tomorrow = new Date();
			tomorrow.setHours(tomorrow.getMinutes() + 30);
			localStorage.setItem('abt5td', tomorrow.getTime());
		}
		return (tg==='true');
	}

	isAuthorized() {
		if (this.getItem('apptoken')) {
			//kontrol valdiacniho cookies
			return true;//Config.isSigned;
		}
		//return true;
		else
			return false;
	}

	clearToken() {
		this.removeItem('apptoken');
	}

	get token() {
		if (this.isAuthorized())
			return this.getItem('apptoken');
		else
			return null;
	}
	set token(val) {
		this.setItem('apptoken', val);
	}

	clearUserName() {
		this.removeItem('userName');
	}

	get userName() {
		if (this.isAuthorized()) {
			return this.getItem('userName');
		}
		else
			return null;
	}
	set userName(val) {
		this.setItem('userName', val);
	}

	clearUser() {
		this.removeItem('user');
	}

	get user() {
		if (this.isAuthorized()) {
			return JSON.parse(this.getItem('user'));
		}
		else
			return null;
	}
	set user(val) {
		this.setItem('user', JSON.stringify(val));
	}

	get currencyId() {
		var id = read_cookie('currencyid');
		if (Array.isArray(id)) {
			return this.getDefaultCurrencyId();
		}
		else
			return id;
	}
	set currencyId(val) {
		bake_cookie('currencyid', val);
	}

	get countryId() {
		var id = this.getItem('countryid');
		if (!id) {
			return this.getDefaultCountryId();
		}
		else
			return id;
	}
	set countryId(val) {
		this.setItem('countryid', val);
	}

	get rememberMe() {
		var val = this.getItem('rememberMe');
		if (val == 'true') {
			return val;
		}
		else
			return false;
	}
	set rememberMe(val) {
		var v = JSON.stringify(val);
		this.setItem('rememberMe', v);
	}


	get language() {
		return "sk";
	}

	get master() {
		return JSON.parse(this.getSessionItem('master'));
	}
	set master(val) {
		this.setSessionItem('master', JSON.stringify(val));
	}

	get cart() {
		var _cart = this.getItem('cart');
		if (!_cart) {
			_cart = JSON.stringify(new Cart());
			this.setItem('cart', _cart);
		}
		return JSON.parse(_cart);
	}
	set cart(val) {
		this.setItem('cart', JSON.stringify(val));
		Storage.instance().cachedCart = null;
	}

	get customer() {
		return JSON.parse(this.getSessionItem('custInfo'));
	}
	set customer(val) {
		this.setSessionItem('custInfo', JSON.stringify(val));
	}

	get cachedCart() {
		return JSON.parse(this.getSessionItem('cachedCart'));
	}
	set cachedCart(val) {
		this.setSessionItem('cachedCart', JSON.stringify(val));
	}

	getDefaultCurrencyId() {
		return null;
	}

	getDefaultCountryId() {
		return null;
	}

	get visitedProducts() {
		var _visited = this.getSessionItem('visited');
		if (!_visited) {
			_visited = JSON.stringify([]);
			this.setSessionItem('visited', _visited);
		}
		return JSON.parse(_visited);
	}
	set visitedProducts(val) {
		this.setSessionItem('visited', JSON.stringify(val));
	}

	get productsUtmSource() {
		var _productsUtmSource = this.getSessionItem('productsUtmSource');
		if (!_productsUtmSource) {
			_productsUtmSource = JSON.stringify([]);
			this.setSessionItem('productsUtmSource', _productsUtmSource);
		}
		return JSON.parse(_productsUtmSource);
	}
	set productsUtmSource(val) {
		this.setSessionItem('productsUtmSource', JSON.stringify(val));
	}

	//Note:
	//Martine, proc pouzivas JSON.stringify and JSON.parse? Ja myslim, ze storage dokaze ukladat strukturovana data.
	//Pridelavas si praci.

}

window.onstorage = function (e) {
	//Leave this empty
	//or add code to handle
	//the event
};
