import { GtmProvider } from './../Providers/GtmProvider';
import { LuigisBoxProvider } from './../Providers/LuigisBoxProvider';
import { RtbHouseProvider } from './../Providers/RtbHouseProvider';
import { Storage } from './../Core/Storage';


class Gtm {

	constructor() {
		this.meta = {
			ipaddress: '',
			currencyCode: '',
			locale: '',
		};
		this.cart = null;
		this.user = null;
		this.order = null;
		this.loaded = false;

		this.plist = {};
		this.breadcrumbs = '';
		//this.settings = Object.assign({}, defaults, options);
	}

	loadPage() {
		let title = window.document.title.replace(this.meta.title).trim();
		console.log(Storage.instance());
		if (!this.cart)
			this.cart = Storage.instance()?.cart;
		if (!this.user)
			this.user = Storage.instance()?.user;

		if (this.meta) {
			GtmProvider.sendMetaData(this.meta.currencyCode, this.meta.locale, title);
			if (this.meta.ipAddress) {
				GtmProvider.sendSessionData(this.meta.ipAddress);
			}
		}
		for (var key in this.plist) {
			GtmProvider.sendProductList(this.plist[key]);
		}

		if (this.cart) {
			GtmProvider.sendCart(this.cart);
			
		}

		if (this.order)
			Gtm.sendorder(this.order);

		if (this.breadcrumbs) {
			GtmProvider.sendBreadCrumb(this.breadcrumbs);
		}
		GtmProvider.sendPage();
		this.loaded = true;
	}

	clickProduct = function (listName, idx) {
		var list = plist[listName];
		if (list) {
			var p = list[idx];
			if (p) {
				GtmProvider.onProductClick(p);
			}
		}
	}

	loadHome() {
		if (!this.loaded) {
			GtmProvider.sendHome();
			RtbHouseProvider.sendHome(this.user);
			this.loadPage();
			if (this.user) {
				GtmProvider.sendUser(this.user);
			}
		}
	}

	load404() {
		if (!this.loaded) {
			GtmProvider.sendError(404, "Stránka nenalezena");
			this.loadPage();
			if (this.user) {
				GtmProvider.sendUser(this.user);
			}
		}
	}

	load500() {
		if (!this.loaded) {
			GtmProvider.sendError(500, "Chyba na stránce");
			this.loadPage();
			if (this.user) {
				GtmProvider.sendUser(this.user);
			}
		}
	}

	loadErr(status,msg) {
		if (!this.loaded) {
			GtmProvider.sendError(status, msg);
			this.loadPage();
			if (this.user) {
				GtmProvider.sendUser(this.user);
			}
		}
	}

	loadCategory(id,pageIdx, filter, ordering) {
		if (!this.loaded) {
			window.Targito.push('event', 'category_view', { 'id': id });
			GtmProvider.sendPriceList(pageIdx, filter, ordering);
			RtbHouseProvider.loadCategory(id, this.user);
			this.loadPage();	
			if (this.user) {
				GtmProvider.sendUser(this.user);
			}
		}
	}

	loadAll() {
		if (!this.loaded) {
			this.loadPage();
			if (this.user) {
				GtmProvider.sendUser(this.user);
			}
		}
	}

	loadSale() {
		if (!this.loaded) {
			RtbHouseProvider.loadSale(this.user);
			this.loadPage();
			if (this.user) {
				GtmProvider.sendUser(this.user);
			}
		}
	}

	loadFlyer() {
		if (!this.loaded) {
			this.loadPage();
			if (this.user) {
				GtmProvider.sendUser(this.user);
			}
		}
	}

	loadProduct(product) {
		if (!this.loaded) {
			GtmProvider.sendProductDetail(product);
			LuigisBoxProvider.sendProductDetail(product);
			RtbHouseProvider.loadProduct(product, this.user);
			this.loadPage();
			if (this.user) {
				GtmProvider.sendUser(this.user);
			}
		}
	}

	loadSearch(searchTerm, totalCount, pageIdx, filter, ordering) {
		if (!this.loaded) {			
			GtmProvider.sendSearch(searchTerm, totalCount);
			RtbHouseProvider.loadSearch(pageIdx, this.user);
			this.loadPage();
			if (this.user) {
				GtmProvider.sendUser(this.user);
			}
		}
	}

	loadCart(cart) {
		if (!this.loaded) {
			GtmProvider.sendCart(cart);
			GtmProvider.sendCartToAdForm(cart);
			GtmProvider.sendcheckoutPage(1);

			RtbHouseProvider.loadCart(cart, this.user);
			this.loadPage();
			if (this.user) {
				GtmProvider.sendUser(this.user);
			}
		}
	}

	loadCheckoutCross(order) {
		if (!this.loaded) {
			GtmProvider.sendcheckoutPage(3);
			GtmProvider.sendCart(order);
			this.loadPage();
			if (this.user) {
				GtmProvider.sendUser(this.user);
			}
		}
	}

	loadCheckoutUser(order) {
		if (!this.loaded) {
			GtmProvider.sendcheckoutPage(4);
			GtmProvider.sendPageToAdForm();
			GtmProvider.sendCart(order);
			RtbHouseProvider.sendCart(this.user);
			this.loadPage();
			if (this.user) {
				GtmProvider.sendUser(this.user);
			}
		}
	}

	loadCheckoutDelivery(order) {
		if (!this.loaded) {
			GtmProvider.sendcheckoutPage(2);
			GtmProvider.sendPageToAdForm();
			GtmProvider.sendCart(order);
			this.loadPage();
			if (this.user) {
				GtmProvider.sendUser(this.user);
			}
		}
	}

	loadCheckoutFinish(order) {
		if (!this.loaded) {
			GtmProvider.sendorder(order);
			LuigisBoxProvider.sendorder(order);
			GtmProvider.sendPurchase(order.currency.code);
			GtmProvider.sendUserFromOrder(order, this.user);
			
			RtbHouseProvider.sendOrder(order, this.user);
			//order checkout to AP tracker (_AP_tracker is included only for czech channel )
			window._AP_tracker?.convert({ amount: order.partialPriceTable.cart.totalPriceExclVat, advSub: order.invoiceCode });
			this.loadPage();
		
		}
	}

	loadAccount(subpage) {
		if (!this.loaded) {
			GtmProvider.sendAccount(subpage);
			this.loadPage();
		}
	}

	onRegistrationNews(form) {

		GtmProvider.onRegistrationNews(form);
	}

	onLogin(loginType) {
		GtmProvider.onLogin(loginType);
	}

	onRegistration(registerType) {
		GtmProvider.onLogin(registerType);
	}

	onRemoveCartAction(cartItem) {
		GtmProvider.onRemoveCartAction(cartItem.product, cartItem.count);
	}

	onAddCartAction(product, quantity, listName, position) {
		GtmProvider.onAddCartAction(product, listName, position, quantity);
	}

	onAddProductToCart(product, quantity) {
		GtmProvider.onAddProductCartAction(product, quantity);
		RtbHouseProvider.onAddProductCartAction(product, quantity);
	}

	onCartUpdate(data) {
		GtmProvider.onCartUpdateAction(data);
	}
}

window.gtm = new Gtm();

